.fc-icon{
    background-color: #007bff!important;

}
.fc-button-primary{
    background-color: #007bff!important;
    padding: 3 3 3 3 !important;
    border-color:  #0171e7!important;
    font-size: 14px !important;
}

@media(max-width:480px){
.fc-icon{
    background-color: #007bff!important;
    margin-top: '0px'!important;

}
.fc-button-primary{
    background-color: #007bff!important;
    padding: 3px 3px 3px 3px !important;
    border-color:  #0171e7!important;
    font-size: 10px !important;
}
.fc-toolbar-title{
    font-size: 14px !important;
}
.fc-button-group{
    display: block!important;
}
.fc-col-header-cell-cushion {
    font-size: 12px!important;
}
}